<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item"><router-link to="/search/index">プログラム検索</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">プログラム詳細</li>
    </ol>
  </nav>
  <div class="container" v-if="isDisplay">
    <h1>
      <ul class="tag-list">
        <li class="tag-list-item tag-full" v-if="event && event.categoryType">{{ event.categoryType.name }}</li>
        <li class="tag-list-item tag-full" v-if="event && event.kindType">{{ event.kindType.name }}</li>
        <li class="tag-list-item" v-for="membership in eventMemberships" :key="membership.id">{{ membership.name }}</li>
        <li class="tag-list-item tag-new" v-if="isNew">新着</li>
        <li class="tag-list-item tag-paid" v-if="isPaid">有料</li>
        <li class="badge bg-primary" style="font-size: 11px !important;" v-if="event && event.participants">{{ receptionStatus(event?.participants, event) }}</li>
      </ul>
      <span>{{ event?.title }}</span>
    </h1>
    <Splide ref="splide" class="event-slick-image mb-5" :options="options" :has-track="false"
      v-if="event?.isDisplayImage && event?.images && event?.images.length > 0">
      <SplideTrack>
        <SplideSlide v-for="image in event?.images" :key="image.id"><img :src="image.url" :alt="image.originalName">
        </SplideSlide>
        <!-- <SplideSlide><img src="@images/sample2.jpg" alt=""></SplideSlide>
        <SplideSlide><img src="@images/sample1.jpg" alt=""></SplideSlide> -->
      </SplideTrack>
      <div class="splide__arrows" v-if="event?.images && event?.images.length > 1">
        <button class="splide__arrow splide__arrow--prev slick-prev slick-arrow">Prev</button>
        <button class="splide__arrow splide__arrow--next slick-next slick-arrow">Next</button>
      </div>
      <ul class="splide__pagination slick-dots">
      </ul>
    </Splide>

    <div class="event-info tab-content">
      <div class="requirements-contents tab-pane active guideline-item">
        <div>
          <h6>種類</h6>
          <p class="ps-2" v-if="event?.kindType">
            {{ event?.kindType.name }}
          </p>
        </div>
        <div class="mt-4">
          <h6>拠点</h6>
          <p class="ps-2" v-if="event?.base">
            <a :href="event?.base.url" target="_blank" v-if="event?.base">{{ event?.base.name }}</a>
          </p>
        </div>
        <div class="mt-4">
          <h6>カテゴリー</h6>
          <p class="ps-2" v-if="event?.categoryType">
            {{ event?.categoryType.name }}
          </p>
        </div>
        <div class="mt-4">
          <h6>対象</h6>
          <p class="ps-2">
            {{ minTarget?.name }} ～ {{ maxTarget?.name }}
          </p>
        </div>
        <div class="mt-4">
          <h6>申込先YMCA</h6>
          <p class="ps-2" v-if="event?.department">
            {{ event.department.name }}
          </p>
        </div>
        <div class="mt-4">
          <h6>定員</h6>
          <p class="ps-2">
            {{ event?.displayCapacity }}
          </p>
        </div>
        <div class="mt-4">
          <h6>料金</h6>
          <p class="ps-2" v-if="event?.priceDetail">
            {{ event?.priceDetail.toLocaleString() }}円
          </p>
          <p class="ps-2" v-else>
            0円
          </p>
        </div>
        <div class="mt-4">
          <h6>開催期間</h6>
          <p class="ps-2" v-for="schedule in eventSchedules" :key=schedule.id>
            <template v-if="schedule.startAt && schedule.endAt && schedule.startAt === schedule.endAt">
              <template v-if="!event?.participants.find(p => p.eventCode === schedule.eventCode)?.isApplicable">
                {{ toDateSlash(schedule.startAt) }}{{ displayReceptionStatus(event?.participants.find(p => p.eventCode === schedule.eventCode)) }}
              </template>
              <template v-else>
                {{ toDateSlash(schedule.startAt) }}
              </template>
            </template>
            <template v-else-if="schedule.startAt && schedule.endAt">
              <template v-if="!event?.participants.find(p => p.eventCode === schedule.eventCode)?.isApplicable">
                {{ toDateSlash(schedule.startAt) }} ～ {{ toDateSlash(schedule.endAt) }}{{ displayReceptionStatus(event?.participants.find(p => p.eventCode === schedule.eventCode)) }}
              </template>
              <template v-else>
                {{ toDateSlash(schedule.startAt) }} ～ {{ toDateSlash(schedule.endAt) }}
              </template>
            </template>
          </p>
        </div>
        <div class="mt-4">
          <h6>開催地/行き先</h6>
          <p class="ps-2" v-if="event?.destination">
            {{ event?.destination }}
          </p>
        </div>
        <div class="mt-4">
          <h6>集合解散</h6>
          <p class="ps-2" v-if="event?.gatheringDisbanding">
          <p v-html="event?.gatheringDisbanding.replace(/\n/g, '<br>')">
          </p>
          </p>
        </div>
        <div class="mt-4">
          <h6>詳細</h6>
          <p class="ps-2" v-if="event?.content">
            <!-- 【詳細】<br/> -->
          <p v-html="event?.content.replace(/\n/g, '<br>')">
          </p>
          </p>
          <p class="ps-2">
            <template v-if="event?.includedInPrice">《参加費に含まれるもの》<br />
              <p class="ps-2" v-html="event?.includedInPrice.replace(/\n/g, '<br>')"></p><br />
            </template>
            <template v-if="event?.excludedInPrice">《参加費に含まれないもの》<br />
              <p class="ps-2" v-html="event?.excludedInPrice.replace(/\n/g, '<br>')"></p><br />
            </template>
            <template v-if="event?.link">【ホームページ】<br />
              <a :href="event?.link" target="_blank">{{ event?.anchorText ? event.anchorText : event?.link
                }}</a><br /></template>
          </p>
          <p class="ps-2" v-if="event?.manager">
            【担　当】{{ event?.manager }}<br />
          </p>
          <!-- <p class="ps-2" v-html="event?.content.replace(/\n/g, '<br>')"></p> -->
          <p class="ps-2" v-if="event?.other" v-html="event?.other.replace(/\n/g, '<br>')"></p>
          <!-- <template v-if="isMobile">
            <table class="table" v-if="event?.timeTables && event?.timeTables.length > 0">
              <template v-for="(col, index) in event.timeTableCols" :key="col">
                <thead>
                  <tr v-if="event.timeTables[0][`col${index + 2}`]">
                    <th>
                      <span v-html="event.timeTables[0][`col1`]"></span>
                    </th>
                    <th>
                      <span v-html="event.timeTables[0][`col${index + 2}`]"></span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template  v-for="(row, index2) in event.timeTables.slice(1)" :key="row" >
                    <tr v-if="row[`col${index + 2}`]" >
                      <td>
                        <span v-if="row[`col${index + 2}`]" v-html="row.col1.replace(/\n/g, '<br/>')"></span>
                      </td>
                      <td>
                        <span v-if="row[`col${index + 2}`]" v-html="row[`col${index + 2}`].replace(/\n/g, '<br/>')"></span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </table>
          </template>
          <template v-else> -->
            <table class="table" v-if="event?.timeTables && event?.timeTables.length > 0">
              <thead>
                <tr>
                  <th v-for="(col, index) in event.timeTableCols " :key="col">
                    <p v-html="event.timeTables[0][`col${index + 1}`]"></p>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in event.timeTables.slice(1)" :key="row.id">
                  <td v-for="(col, index) in event.timeTableCols " :key="col">
                    <p v-html="row[`col${index + 1}`].replace(/\n/g, '<br/>')"></p>
                  </td>
                </tr>
              </tbody>
            </table>
          <!-- </template> -->
          <p class="ps-2" v-if="event?.sideNote" v-html="event?.sideNote.replace(/\n/g, '<br>')"></p>
        </div>
        <div class="mt-4" v-if="event?.department">
          <h6>問い合わせ</h6>
          <p class="ps-2" v-if="event.department">{{ event.department.name }}</p>
          <p class="ps-2" v-if="event.department">メールアドレス：<a href="mailto:{{ event.department.mailAddress }}">{{
    event.department.mailAddress }}</a></p>
          <p class="ps-2" v-if="event.department">電話番号：<a href="tel:{{ event.department.phoneNumber }}">{{
    event.department.phoneNumber }}</a></p>
          <p class="ps-2" v-if="event.department.reception">受付時間：{{ event.department.reception }}</p>


          <!-- <p class="ps-2"><a :href="event?.department.url" target="_blank">{{ event?.department.url }}</a></p> -->
        </div>
      </div>
      <div class="btn-area" v-if="memberStore.isLoggedIn">
        <template v-if="isApply && isParticipatable(parent, children)">
          <button class="btn btn-apply favo favo-active" @click="handleFavorite(false)"
            v-if="isFavorite(event)">
            <span class="favo-icon"><i class="bi bi-star-fill"></i></span><span>お気に入り済み</span>
          </button>
          <button class="btn btn-apply favo" @click="handleFavorite(true)" v-else>
            <span class="favo-icon"><i class="bi bi-star-fill"></i></span><span>お気に入り登録</span>
          </button>
        </template>
        <template v-if="event?.isApply && isApply && isParticipatable(parent, children)">
          <button disabled class="btn btn-apply entry-program" v-if="event && isApplied(event)"><span>応募済み</span><i
            class="bi bi-chevron-right"></i></button>
          <button disabled class="btn btn-apply entry-program" v-else-if="event && receptionStatus(event.participants) === '満席'"><span>満席</span><i
            class="bi bi-chevron-right"></i></button>
          <router-link :to="{ path: '/event/entry', query: { id: event?.id } }" class="btn btn-apply entry-program" v-else-if="event && receptionStatus(event.participants) === 'キャンセル待ち'"><span>キャンセル待ちする</span><i
            class="bi bi-chevron-right"></i></router-link>
          <router-link :to="{ path: '/event/entry', query: { id: event?.id } }" class="btn btn-apply entry-program"
            v-else-if="event && !isApplied(event)"><span>予約する</span><i class="bi bi-chevron-right"></i></router-link>
        </template>
        <template v-if="event?.recruitmentPeriodEnd && new Date(event.recruitmentPeriodEnd) < new Date()">
          <button disabled class="btn btn-apply entry-program btn-danger">
            <span>受付終了</span><i class="bi bi-chevron-right"></i>
          </button>
        </template>
      </div>
      <div class="btn-area" v-else-if="event && receptionStatus(event.participants) !== '満席' && event.isApply">
          <router-link to="/login/index" class="btn btn-apply entry-program"><i class="bi bi-box-arrow-in-right"></i>ログイン/メンバー登録</router-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, toRefs, watch, watchEffect } from 'vue'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide'
import '@splidejs/vue-splide/css'
import { API } from '@/api'
import { useRoute, useRouter } from 'vue-router'
import { TEvent, TEventMembership, TEventParticipant, TEventSchedule, TEventTarget } from '@/types/event'
import { toDateSlash, displayReceptionStatus, receptionStatus, getGrade, getAgeWithMonths, toDateHyphenJst } from '@/js/util'
import { NumberSchema } from 'yup'
import { useMemberStore } from '@/stores/member'
import { handleErrors } from '@/config/handleErrors'
import { SEO } from '@/config/seo'
import { useHead } from '@unhead/vue'
import { useLoadingStore } from '@/stores/loading'
import { TMember } from '@/types/member'

const seoTitle = ref<string>('')
const route = useRoute()
watch(
  () => route.name,
  (newRouteName) => {
    if (newRouteName && SEO[newRouteName]) {
      useHead({
        title: () => SEO[newRouteName].title.replace('プログラム詳細', seoTitle.value),
        meta: [
          {
            name: 'description',
            content: SEO[newRouteName].description,
          },
          {
            name: 'keywords',
            content: SEO[newRouteName].keywords,
          },
        ],
      })
    }
  },
  { immediate: true }
)

const props = defineProps<{
  path: string
}>()

const splide = ref()

const event = ref<TEvent>()
const eventTargets = ref<TEventTarget[]>()
const eventMemberships = ref<TEventMembership[]>()
const eventSchedules = ref<TEventSchedule[]>([])
const minTarget = ref<TEventTarget>()
const maxTarget = ref<TEventTarget>()
const memberIds = ref<number[]>([])
const isDisplay = ref<boolean>(false)
const memberMembershipsIds = ref<number[]>([])
const memberStore = useMemberStore()
const parent = ref<TMember>()
const children = ref<TMember[]>()
const parentMembershipsIds = ref<number[]>([])
const childrenMembershipsIds = ref<number[]>([])

// refを使用してslickContainerの参照を作成
const options = ref({
  dots: true,
  arrows: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  rewind: true,
  interval: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  // hasSliderWrapper: true
  // prevArrow: '<div class="slick-prev">Previous</div>'
})

// コンポーネントがマウントされた後にSlickを初期化
onMounted(async () => {
  // if ( splide.value && splide.value.splide ) {
  //     console.log( splide.value.splide );
  //     splide.value.splide.state.set({pagination:'splide__pagination slick-dots'})
  // }
  try {
    const {
      event: fetchEvent,
      eventTargets: fetchEventTargets,
      eventMemberships: memberships,
      eventSchedules: fetchSchedules,
      memberIds: fetchMemberIds,
      memberMembershipsIds: fetchMemberMembershipsIds,
      parent: fetchParent,
      children: fetchChildren,
      parentMembershipsIds: fetchparentMembershipsIds,
      childrenMembershipsIds: fetchchildrenMembershipsIds,
    } = await TEvent.fetch({ path: props.path, id: useMemberStore().id })
    event.value = fetchEvent
    eventTargets.value = fetchEventTargets
    eventMemberships.value = memberships
    eventSchedules.value = fetchSchedules
    memberIds.value = fetchMemberIds
    memberMembershipsIds.value = fetchMemberMembershipsIds
    parent.value = fetchParent
    children.value = fetchChildren
    parentMembershipsIds.value = fetchparentMembershipsIds
    childrenMembershipsIds.value = fetchchildrenMembershipsIds
    if (eventTargets.value.length > 0) {
      const minTargetData = eventTargets.value.reduce((min: TEventTarget, current: TEventTarget) => {
        return min.id < current.id ? min : current;
      })
      const maxTargetData = eventTargets.value.reduce((max, current) => {
        return max.id > current.id ? max : current;
      })
      minTarget.value = { ...minTargetData }
      maxTarget.value = { ...maxTargetData }
      if ((event.value.startAge || event.value.startMonthAge) && (event.value.endAge || event.value.endMonthAge)) {
        const startAge = event.value.startAge ?? 0
        const startMonthAge = event.value.startMonthAge ?? 0
        const endAge = event.value.endAge ?? 0
        const endMonthAge = event.value.endMonthAge ?? 0
        const startAgeWithMonth = formatToYYMM(startAge, startMonthAge)
        const endAgeWithMonth = formatToYYMM(endAge, endMonthAge)

        if (startAgeWithMonth <= endAgeWithMonth) {
          if (startAge < minTarget.value.id - 1) {
            minTarget.value.name = `${startAge}歳${startMonthAge > 0 ? startMonthAge + 'ヶ月' : ''}`
          }
          if (endAge > maxTarget.value.id - 1) {
            maxTarget.value.name = `${endAge}歳${endMonthAge > 0 ? endMonthAge + 'ヶ月' : ''}`
          }
        }
      }
    }
    isDisplay.value = event.value.isDisplay
    if (!isDisplay.value) {
      router.push({ name: 'Top' })
    }
    seoTitle.value = event.value.title
    window.addEventListener('resize', handleResize);
  } catch (e: any) {
    handleErrors(e)
  }
})

function formatToYYMM(years: number, months: number): string {
  const formattedYears = years.toString().padStart(2, '0')
  const formattedMonths = months.toString().padStart(2, '0')
  return `${formattedYears}${formattedMonths}`
}

// const resetTime = (date) => {
//   if (date) {
//     return date.setHours(0, 0, 0, 0)
//   }
// }

const router = useRouter()

const stays = (startAt, endAt) => {
  if (!startAt || !endAt) return 0
  const date1 = new Date(startAt)
  const date2 = new Date(endAt)
  const timeDiff = Math.abs(date2.getTime() - date1.getTime())
  return Math.ceil(timeDiff / (1000 * 60 * 60 * 24))
}

const isPaid = computed(() => {
  if (event.value?.priceDetail) {
    if (Number(event.value.priceDetail) === 0) {
      return false
    } else {
      return true
    }
  } else {
    return false
  }
})
const isNew = computed(() => {
  if (event.value?.displayPeriodStart) {
    const beforeWeek = new Date()
    beforeWeek.setDate(beforeWeek.getDate() - 7)
    return beforeWeek <= new Date(event.value.displayPeriodStart)
  } else {
    return false
  }
})

const formattedTime = (time: string): string => {
  if (time) {
    const [hours, minutes] = time.split(':');
    return `${hours}:${minutes}`;
  }
  return ''
}

const isApply = computed(() => {
  // 対象のメンバー種別じゃなかったら申込できない
  const emss = eventMemberships.value?.map(v => v.id)
  if (emss && emss.length > 0) {
    return emss.some(id => memberMembershipsIds.value.includes(id))
  } else {
    return false
  }
})

// const isApplied = computed(() => (event: TEvent | null): boolean => {
//   if (event?.eventMember === null || event?.eventMember === undefined) return true
//   let applyCount = event.eventMember.length
//   // let family = memberIds.value
//   let family: number[] = []
//   if (event.applicantParent) family.push(...memberIds.value.filter(id => id === useMemberStore().id)) // 親を削除
//   if (event.applicantChild) family.push(...memberIds.value.filter(id => id !== useMemberStore().id)) // 親以外を残す
//   return applyCount >= family.length * eventSchedules.value.length
// }
// )

const isApplied = computed(() => (event: TEvent | null): boolean => {
  if (event?.eventMember === null || event?.eventMember === undefined) return true
  let applyCount = event.eventMember.length
  let family: number[] = []
  if (!event.applicantParent && event.applicantChild && memberIds.value.length === 1) {
    return false
  } else {
    if (event.applicantParent) family.push(...memberIds.value.filter(id => Number(id) === Number(useMemberStore().id)) ) // 親を削除
    if (event.applicantChild) family.push(...memberIds.value.filter(id => Number(id) !== Number(useMemberStore().id))) // 親以外を残す
    return applyCount >= family.length * eventSchedules.value.length
  }
})

const isFavorite = computed(() => {
  return (event) => {
    const fav = event.favorites.find(f =>
      f.memberId === useMemberStore().$state.id && event.id === f.eventId
    )
    return fav ? true : false;
  }
})

const handleFavorite = async (isFavorite: boolean) => {
  try {
    if (!event.value) return
    useLoadingStore().start()
    const response = await TEvent.favorite({ eventId: event.value.id, isFavorite })
    if (response.data.status === 'failed') {
      alert('お気に入りの更新に失敗しました')
    }
    const {
      event: fetchEvent,
      eventTargets: fetchEventTargets,
      eventMemberships: memberships,
      eventSchedules: fetchSchedules,
      memberIds: fetchMemberIds,
      memberMembershipsIds: fetchMemberMembershipsIds,
      parent: fetchParent,
      children: fetchChildren,
      parentMembershipsIds: fetchparentMembershipsIds,
      childrenMembershipsIds: fetchchildrenMembershipsIds,
    } = await TEvent.fetch({ path: props.path, id: useMemberStore().id })
    event.value = fetchEvent
    eventTargets.value = fetchEventTargets
    eventMemberships.value = memberships
    eventSchedules.value = fetchSchedules
    memberIds.value = fetchMemberIds
    memberMembershipsIds.value = fetchMemberMembershipsIds
    parent.value = fetchParent
    children.value = fetchChildren
    parentMembershipsIds.value = fetchparentMembershipsIds
    childrenMembershipsIds.value = fetchchildrenMembershipsIds
  } catch (e: any) {
    handleErrors(e)
  } finally {
    useLoadingStore().stop()
  }
}

const get2digFiscalYear = (dateString) => {
  const now = new Date(dateString)
  const year = now.getFullYear()
  const month = now.getMonth() + 1
  const fiscalYear = month < 4 ? year - 1 : year
  return fiscalYear % 100
}

function isParticipatable(parent: TMember | undefined, children: TMember[] | undefined): boolean {
  let allMembers: TMember[] = [];
  const emss = eventMemberships.value?.map(v => v.id)
  if (emss && emss.length > 0) {
    if (parent && (emss.some(id => parentMembershipsIds.value.includes(id)))) {
      allMembers.push(parent);
    }
    if ((children && children.length > 0) && (emss.some(id => childrenMembershipsIds.value.includes(id)))) {
        allMembers.push(...children);
    }
  }

  for (const member of allMembers) {
    if (!member) continue;
    let grade = getGrade(member.birthday)
    // 次年度のプログラムだったら学年をあげて考える
    if (event.value && event.value?.serverNow) {
      if (event.value.fiscalYear > get2digFiscalYear(event.value?.serverNow)) {
        grade = grade + (event.value.fiscalYear - get2digFiscalYear(event.value?.serverNow) )
        if (grade >= 21) {
          grade = 20
        } 
      }
    }

    let isParticipatableGrade: number[] = []
    for (const target of eventTargets.value ? eventTargets.value : []) {
      switch (target.name) {
        case '0歳': isParticipatableGrade.push(1)
          break
        case '1歳': isParticipatableGrade.push(2)
          break
        case '2歳': isParticipatableGrade.push(3)
          break
        case '年少': isParticipatableGrade.push(4)
          break
        case '年中': isParticipatableGrade.push(5)
          break
        case '年長': isParticipatableGrade.push(6)
          break
        case '小学1年生': isParticipatableGrade.push(7)
          break
        case '小学2年生': isParticipatableGrade.push(8)
          break
        case '小学3年生': isParticipatableGrade.push(9)
          break
        case '小学4年生': isParticipatableGrade.push(10)
          break
        case '小学5年生': isParticipatableGrade.push(11)
          break
        case '小学6年生': isParticipatableGrade.push(12)
          break
        case '中学1年生': isParticipatableGrade.push(13)
          break
        case '中学2年生': isParticipatableGrade.push(14)
          break
        case '中学3年生': isParticipatableGrade.push(15)
          break
        case '高校1年生': isParticipatableGrade.push(16)
          break
        case '高校2年生': isParticipatableGrade.push(17)
          break
        case '高校3年生': isParticipatableGrade.push(18)
          break
        case '大学生・社会人': isParticipatableGrade.push(19)
          break
        case '成人': isParticipatableGrade.push(20)
          break
      }
    }

    // 開始年齢/月齢、終了年齢/月齢が指定されている場合
    if (event.value?.startAge || event.value?.startMonthAge || event.value?.endAge || event.value?.endMonthAge) {
      const startAt = event.value.schedules[0].startAt
      const startAtString = toDateHyphenJst(startAt)
      const startAtDate = new Date(`${startAtString}T00:00:00`)
      const endAt = event.value.schedules[0].endAt
      const endAtString = toDateHyphenJst(endAt)
      const endAtDate = new Date(`${endAtString}T00:00:00`)
      const now = new Date()
      let date 
      if ((startAtDate < now) && (now < endAtDate)) {
        date = now
      } else if (startAtDate > now) {
        date = startAtDate
      }
      const age = getAgeWithMonths(date, member.birthday)
      const ageWithMonth = formatToYYMM(age.years, age.months)

      const startAge = event.value.startAge ?? 0
      const startMonthAge = event.value.startMonthAge ?? 0
      const endAge = event.value.endAge ?? 0
      const endMonthAge = event.value.endMonthAge ?? 0
      const startAgeWithMonth = formatToYYMM(startAge, startMonthAge)
      const endAgeWithMonth = formatToYYMM(endAge, endMonthAge)

      if (isParticipatableGrade.includes(grade) || (ageWithMonth >= startAgeWithMonth && ageWithMonth <= endAgeWithMonth)) {
        return true
      }
    } else {
      if (isParticipatableGrade.includes(grade)) {
        return true
      } 
    }
  }
  return false; 
}

// スマホの状態を保持するリアクティブ変数
const isMobile = ref(window.innerWidth <= 768)

// ウィンドウサイズ変更時にスマホかどうかを判断
const handleResize = () => {
  isMobile.value = window.innerWidth <= 768
}

// コンポーネントがアンマウントされたらリスナーを削除
onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})

</script>

<style deep>
@import '@/css/app.css';

.event-info {
  width: 100% !important;
  max-width: 730px !important;
  margin: 0 auto !important;
}

.slick-next:before,
.slick-prev:before {
  color: #ff7419 !important;
  font-size: 40px !important;
}

.slick-prev {
  left: -45px !important;
}

.event-slick-image {
  width: 100% !important;
  max-height: 500px !important;
  max-width: 730px !important;
  margin: 0 auto 40px !important;
}

.slick-list {
  max-height: 500px !important;
}

.slick-initialized .slick-slide {
  padding-top: calc(100vw * (500 / 800)) !important;
  position: relative !important;
  max-height: 500px !important;
}

.slick-slide img {
  display: block !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
}

@media (max-width: 809px) {

  .slick-prev,
  .slick-next {
    z-index: 10 !important;
  }

  .slick-prev {
    left: 5px !important;
  }

  .slick-next {
    right: 25px !important;
  }
}
</style>
