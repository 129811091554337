import { API } from '../api'
import { TMember } from './member'

const resource = '/api/top'

export type TCategoryType = {
  id: number
  name: string
}

export class TProgramList {
  id: number
  type: number
  sortOrder: number
  title: string
  fiscalYear: string
  category: number
  constructor(data: any) {
    this.id = data.id
    this.type = data.type
    this.sortOrder = data.sort_order
    this.title = data.title
    this.fiscalYear = data.fiscal_year
    this.category = data.category
  }
}

export class TEvent {
  id: number
  path: string
  title: string
  category: number
  content: string
  capacity: number
  displayCapacity: string
  target: number
  priceDetail: string
  includedInPrice: string
  excludedInPrice: string
  paymentMethod: number
  destination: string
  gatheringDisbanding: string
  manager: string
  other: string
  displayPeriodStart: Date
  displayPeriodEnd: Date
  recruitmentPeriodStart: Date
  recruitmentPeriodEnd: Date
  applicantParent: boolean
  applicantChild: boolean
  item: string
  messageBefore: string
  schedule: string
  scheduleName: string
  messageAfter: string
  categoryType: TCategoryType
  schedules: TEventSchedule[]
  images: TImage[]
  targets: TTargetType[]
  // payments: TPayment[]
  kindType: TKindType
  startAge: number
  startMonthAge: number
  endAge: number
  endMonthAge: number

  constructor (data: any) {
    this.id = Number(data.id) || 0
    this.path = data.path
    this.title = data.title
    this.category = data.category
    this.content = data.content
    this.capacity = Number(data.capacity)
    this.displayCapacity = data.display_capacity
    this.target = Number(data.target)
    this.priceDetail = data.price_detail 
    this.includedInPrice = data.included_in_price
    this.excludedInPrice = data.excluded_in_price
    this.paymentMethod = Number(data.payment_method)
    this.destination = data.destination
    this.gatheringDisbanding = data.gathering_disbanding
    this.manager = data.manager
    this.other = data.other
    this.displayPeriodStart = data.display_period_start
    this.displayPeriodEnd = data.display_period_end
    this.recruitmentPeriodStart = data.recruitment_period_start
    this.recruitmentPeriodEnd = data.recruitment_period_end
    this.applicantParent = data.applicant_parent
    this.applicantChild = data.applicant_child
    this.item = data.item
    this.messageBefore = data.message_before
    this.schedule = data.schedule
    this.scheduleName = data.schedule_name
    this.messageAfter = data.message_after
    this.categoryType = data.category_type
    this.schedules = ('schedules' in data) ? (Object.keys(data.schedules).map(key => data.schedules[key]) as any[]).map(d => new TEventSchedule(d)) : []
    this.images = ('images' in data) ? (Object.keys(data.images).map(key => data.images[key]) as any[]).map(d => new TImage(d)) : []
    this.targets = ('target_types' in data) ? (Object.keys(data.target_types).map(key => data.target_types[key]) as any[]).map(d => new TTargetType(d)) : []
    this.kindType = data.kind_type
    this.startAge = data.start_age
    this.startMonthAge = data.start_month_age
    this.endAge = data.end_age
    this.endMonthAge = data.end_month_age
  }

  static fetch () {
    return API.get(resource).then((r) => { 
      let events: any = []
      // for(const event of Object.entries(r.data.events)) {
      //   events.push((event as any[]).map(data => new TEvent(data)))
      // }

      for (const [key, value] of Object.entries(r.data.events)) {
        events.push((value as any[]).map(data => new TEvent(data)));
      }
      return {
        events: events,
        notices: (r.data.notices as any[]).map(data => new TNotice(data)),
        publicEvents: (r.data.publicEvents as any[]).map(data => new TProgramList(data)),
        pastEvents: (r.data.pastEvents as any[]).map(data => new TProgramList(data)),
      }
    })
  }
}

export class TNotice {
  id: number
  path: string
  title: string
  content: string
  membership: number
  displayStartAt: Date
  displayEndAt: Date

  constructor(data: any) {
    this.id = data.id,
    this.path = data.path,
    this.title = data.title,
    this.content = data.content,
    this.membership = data.membership,
    this.displayStartAt = data.display_start_at,
    this.displayEndAt = data.display_end_at
  }
  
  static fetchNoticeDetail ({ path }: { path: string }) {
    const params = { path: path }
    return API.get(resource.concat('/notice-detail'), { params }).then((r) => {
      return {
          notice: new TNotice(r.data.notice),
        }
    })
  }
}



export class TEventMembership {
  id: number
  name: string
  pivot: {}

  constructor(data: any) {
    this.id = data.id,
    this.name = data.name,
    this.pivot = data.pivot
  }
}

export class TEventSchedule {
  id: number
  eventId: number
  eventCode: string
  startAt: Date
  endAt: Date
  isChecked: boolean

  constructor(data: any) {
    this.id = data.id
    this.eventId = data.event_id
    this.eventCode = data.event_code
    this.startAt = new Date(data.start_at)
    this.endAt = new Date(data.end_at)
    this.isChecked = false
  }
}

export class TImage {
  id: number
  eventId: number
  url: string
  name: string
  originalName: string

  constructor(data: any) {
    this.id = data.id
    this.eventId = data.event_id
    this.url = data.url
    this.name = data.name
    this.originalName = data.original_name
  }
}

export class TTargetType {
  id: number
  groupId: number
  name: string
  constructor(data: any) {
    this.id = data.id
    this.groupId = data.group_id
    this.name = data.name
  }
}

export class TKindType {
  id: number
  name: string
  constructor(data: any) {
    this.id = data.id
    this.name = data.name
  }
}
