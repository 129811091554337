import { SEO } from '@/config/seo'
import type { TEvent, TEventParticipant } from '@/types/event'
import type { TEvent as TEventSearch } from '@/types/search'
import { useHead } from '@unhead/vue'

/**
 * 指定された日付時刻(String)をYYYY-MM-DD HH:MM:SSに変換します
 * @param daytime
 * @returns string YYYY-MM-DD HH:MM:SS
 */
export function stringToDateTimeHyphen (daytime: string | null): string | null {
  if (daytime === null) return null
  const date = new Date(daytime)
  const year = date.getUTCFullYear()
  const month = String(date.getUTCMonth() + 1).padStart(2, '0')
  const day = String(date.getUTCDate()).padStart(2, '0')
  const hours = String(date.getUTCHours()).padStart(2, '0')
  const minutes = String(date.getUTCMinutes()).padStart(2, '0')
  const seconds = String(date.getUTCSeconds()).padStart(2, '0')
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

/**
 * 指定された日付時刻(String)をYYYY/MM/DDに変換します
 * @param daytime: string
 * @returns string YYYY/MM/DD
 */
export function stringToDateSlash (daytime: string | null): string | null {
  if (daytime === null) return null
  const date = new Date(daytime)
  const year = date.getUTCFullYear()
  const month = String(date.getUTCMonth() + 1).padStart(2, '0')
  const day = String(date.getUTCDate()).padStart(2, '0')
  return `${year}/${month}/${day}`
}

/**
 * 指定された日付時刻(Date)をYYYY/MM/DDに変換します
 * @param daytime: Date
 * @returns string YYYY/MM/DD
 */
export function toDateSlash (daytime: Date | undefined | null): string | null {
  if (daytime === null || daytime === undefined) return null
  const dateInJst = new Date(daytime).toLocaleString('ja-JP', {
    timeZone: 'Asia/Tokyo',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
  return dateInJst.replace(/\//g, '/')
}

/**
 * 指定された日付時刻(Date)をYYYY/MM/DDに変換します
 * @param daytime: Date
 * @returns string YYYY/MM/DD
 */
export function toDateSlashJst (daytime: Date | undefined | null): string | null {
  if (daytime === null || daytime === undefined) return null
  const dateInJst = new Date(daytime).toLocaleString('ja-JP', {
    timeZone: 'Asia/Tokyo',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
  return dateInJst.replace(/\//g, '/')
}

/**
 * 指定された日付時刻(Date)をYYYY.MM.DDに変換します
 * @param daytime: Date
 * @returns string YYYY.MM.DD
 */
export function toDateDotJst (daytime: Date | undefined | null): string | null {
  if (daytime === null || daytime === undefined) return null
  const date = new Date(daytime)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}.${month}.${day}`
}

/**
 * 指定された日付時刻(Date)をYYYY年MM月DD日に変換します
 * @param daytime: Date
 * @returns string YYYY年MM月DD日
 */
export function toDateJap (daytime: Date | undefined | null): string | null {
  if (daytime === null || daytime === undefined) return null
  const date = new Date(daytime)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}年${month}月${day}日`
}

/**
 * 指定された日付時刻(Date)をyyyy-mm-dd形式の日付に変換します
 * @param daytime: Date
 * @returns Date | null
 */
export function toDateHyphenJst (daytime: Date | undefined | null): string | null {
  if (daytime === null || daytime === undefined) return null

  // JSTタイムゾーンで日時を取得
  const dateInJst = new Date(daytime).toLocaleString('ja-JP', {
    timeZone: 'Asia/Tokyo',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })

  // 年月日を取得して、yyyy-mm-dd形式に整形
  const [year, month, day] = dateInJst.split('/')
  const formattedDateString = `${year}-${month}-${day}`

  // Dateオブジェクトを返す
  return formattedDateString
}

/**
 * 指定された日付時刻より曜日を出力します
 * @param daytime: Date
 * @returns string 曜日
 */
export function getDayOfWeek (daytime: Date | undefined | null): string | null {
  if (daytime === null || daytime === undefined) return null
  const daysOfWeek = ['日', '月', '火', '水', '木', '金', '土']
  const today = new Date(daytime)
  return daysOfWeek[today.getDay()]
}

/**
 * UUID的に一意な値を作成します
 * @param myStrong
 * @returns string
 */
export async function getUniqueStr (myStrong?: number): Promise<string> {
  let strong = 1000
  if (myStrong !== undefined) strong = myStrong
  return (
    new Date().getTime().toString(16) +
    Math.floor(strong * Math.random()).toString(16)
  )
}

/**
 * 誕生日を渡されると年齢に変換します
 * @param birthDay
 * @returns number 数字
 */
export function getAge (birthDay: Date): number {
  const now: Date = new Date()
  const birthDate: Date = new Date(birthDay)

  let age: number = now.getFullYear() - birthDate.getFullYear()

  // 誕生日がまだ来ていない場合は、1年引く
  if (now.getMonth() < birthDate.getMonth() || (now.getMonth() === birthDate.getMonth() && now.getDate() < birthDate.getDate())) {
    age--
  }

  return age
}

/**
 * 開催日と誕生日を渡されると開催日時点での「年齢」をオブジェクト形式で返します
 * @param startAtDay 開催日
 * @param birthDay 誕生日
 * @returns { years: number, months: number } 年齢を表すオブジェクト
 */
export function getAgeWithMonths (startAtDay: Date, birthDay: Date): { years: number, months: number } {
  const startAt: Date = new Date(startAtDay)
  const birthDate: Date = new Date(birthDay)

  // 年齢を計算
  let years: number = startAt.getFullYear() - birthDate.getFullYear()

  // 誕生日がまだ来ていない場合は1年引く
  if (startAt.getMonth() < birthDate.getMonth() || (startAt.getMonth() === birthDate.getMonth() && startAt.getDate() < birthDate.getDate())) {
    years--
  }

  // 月数を計算
  let months: number = startAt.getMonth() - birthDate.getMonth()

  // 誕生日がまだ来ていない場合（今の月が誕生日の月より前）の調整
  if (months < 0) {
    months += 12
  }

  // 日数による月の調整（現在の日が誕生日の日より前なら1ヶ月引く）
  if (startAt.getDate() < birthDate.getDate()) {
    months--
    if (months < 0) {
      months = 11
      years--
    }
  }

  return { years, months }
}

/**
 * 誕生日を渡されると学年に変換します
 * @param birthDay
 * @returns number 数字
 */
export function getGrade (birthDay: Date): number {
  birthDay = new Date(birthDay)
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  let grade = today.getFullYear() - birthDay.getFullYear()

  // 基準日を4月2日とし、4月1日以前の誕生日を前年とする
  const referenceDate = new Date(today.getFullYear(), 3, 2) // 4月2日
  if (today < referenceDate && birthDay >= new Date(birthDay.getFullYear(), 3, 2)) {
    grade--
  }

  const oneYearOld = new Date(birthDay.getFullYear() + 1, birthDay.getMonth(), birthDay.getDate())
  const twoYearsOld = new Date(birthDay.getFullYear() + 2, birthDay.getMonth(), birthDay.getDate())
  const threeYearsOld = new Date(birthDay.getFullYear() + 3, birthDay.getMonth(), birthDay.getDate())

  if (today < oneYearOld) {
    grade = 1
  } else if (today < twoYearsOld) {
    grade = 2
  } else if (today < threeYearsOld) {
    grade = 3
  }

  if (grade >= 23) {
    grade = 20
  } else if (grade >= 19) {
    grade = 19
  }
  return grade
}

/**
 * 学年を渡されると学年名に変換します
 * @param birthDay
 * @returns number 数字
 */
export function getGradeName (grade: number): string {
  let gradeName = ''
  switch (grade) {
    case 1: gradeName = '0歳'
      break
    case 2: gradeName = '1歳'
      break
    case 3: gradeName = '2歳'
      break
    case 4: gradeName = '年少'
      break
    case 5: gradeName = '年中'
      break
    case 6: gradeName = '年長'
      break
    case 7: gradeName = '小学1年生'
      break
    case 8: gradeName = '小学2年生'
      break
    case 9: gradeName = '小学3年生'
      break
    case 10: gradeName = '小学4年生'
      break
    case 11: gradeName = '小学5年生'
      break
    case 12: gradeName = '小学6年生'
      break
    case 13: gradeName = '中学1年生'
      break
    case 14: gradeName = '中学2年生'
      break
    case 15: gradeName = '中学3年生'
      break
    case 16: gradeName = '高校1年生'
      break
    case 17: gradeName = '高校2年生'
      break
    case 18: gradeName = '高校3年生'
      break
    case 19: gradeName = '大学生・社会人'
      break
    case 20: gradeName = '成人'
      break
  }
  return gradeName
}

export function setSeo (page?: string): void {
  if (page !== undefined && page !== null && SEO[page] !== undefined) {
    useHead({
      title: () => SEO[page].title,
      meta: [
        {
          name: 'description',
          content: SEO[page].description
        },
        {
          name: 'keywords',
          content: SEO[page].keywords
        }
      ]
    })
  } else {
    useHead({
      title: () => SEO.DEFAULT.title,
      meta: [
        {
          name: 'description',
          content: SEO.DEFAULT.description
        },
        {
          name: 'keywords',
          content: SEO.DEFAULT.keywords
        }
      ]
    })
  }
}

/**
 * キャンセル待ち、予約の文字列を返します
 * @param participants: TEventParticipant
 * @returns string
 */
export function receptionStatus (participants: TEventParticipant[], event?: TEvent | TEventSearch): string {
  if (event !== undefined) {
    if (!event.isApply) return ''
  }
  if (participants.length > 1) {
    const isApplicable: string[] = []
    const isWaiting: string[] = []
    const isSoldOut: string[] = []
    participants.forEach(p => {
      if (p.isApplicable) isApplicable.push('予約する')
      if (p.waitlistLimit !== null && !p.isApplicable) {
        if (p.waitlistCount < p.waitlistLimit) isWaiting.push('キャンセル待ち')
        if (p.waitlistCount >= p.waitlistLimit) isSoldOut.push('満席')
      }
    })
    if (isSoldOut.length === participants.length) return '満席'
    if ((isSoldOut.length + isWaiting.length) >= participants.length) return 'キャンセル待ち'
    // if (isApplicable.length === participants.length) {
    //   return '受付中'
    // }
    if (isApplicable.length > 0) return '受付中'
  } else {
    let isApplicable = true
    let isWaiting = false
    let isSoldOut = false
    participants.forEach(p => {
      isApplicable = p.isApplicable
      if (p.waitlistLimit !== null && !isApplicable) {
        isWaiting = p.waitlistCount < p.waitlistLimit
        isSoldOut = p.waitlistCount >= p.waitlistLimit
      }
    })
    if (isSoldOut) return '満席'
    if (isWaiting) return 'キャンセル待ち'
    if (isApplicable) {
      return '受付中'
    }
  }
  return ''
}

/**
 * キャンセル待ち、予約の文字列を返します
 * @param participants: TEventParticipant
 * @returns string
 */
export function displayReceptionStatus (participant: TEventParticipant | undefined): string {
  if (participant === undefined) return ''
  let isApplicable = true
  let isWaiting = false
  let isSoldOut = false
  isApplicable = participant.isApplicable
  if (participant.waitlistLimit !== null && !isApplicable) {
    isWaiting = participant.waitlistCount < participant.waitlistLimit
    isSoldOut = participant.waitlistCount >= participant.waitlistLimit
  }
  if (isSoldOut) return '(満席)'
  if (isWaiting) return '(キャンセル待ち)'
  return ''
}
