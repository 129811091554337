<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">マイページトップ</li>
    </ol>
  </nav>
  <div class="container-fluid">
    <div class="row mypage-contents">
      <side-bar />
      <div class="mypage-contents_contents ml-sm-auto px-md-4 py-4">
        <div class="top--messageArea" v-if="notices && notices.length > 0">
          <h2>メンバー様へのお知らせ</h2>
          <div class="top--messageArea-list">
            <ul class="top--messageArea-list-inner">
              <template v-for="notice in notices" :key="notice.id">
                <li :class="isNew(notice.display_start_at) ? 'new-icon' : ''">
                  <router-link :to="{ name: 'NoticeDetail', params: { path: notice.path } }">
                    <div class="top--messageArea-list-item">
                      <span class="date" v-if="notice.display_start_at">{{ toDateSlash(notice.display_start_at)
                        }}</span>
                      <span class="text">{{ notice.title }}</span>
                    </div>
                  </router-link>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <h1 class="h2">参加予定プログラム</h1>
        <div class="top--section latestWorks mypage-top mb-3">
          <template v-for="(schedule, index) in beforeEvents" :key="schedule.id">
            <div class="latestWorks-inner result-type"
              v-if="schedule.event && schedule.event.images && schedule.event.images.length > 0">
              <img v-if="schedule.event.images && schedule.event.images.length > 0" :src="schedule.event.images[0].url"
                width="100%">
              <img v-else src="@assets/images/blank.gif" width="100%" />
              <div class="latestWorks-inner--textarea">
                <div class="companyName tag-list">
                  <template v-if="schedule.paymentMethods &&schedule.paymentMethods.length > 0 && schedule.paymentMethods[0].paymentId != 3">
                    <template v-if="!(isWaiting(schedule.eventMembers) == 2)">
                      <span class="badge bg-danger" v-if="isPaid(schedule.eventMembers) == 0">未入金</span>
                      <span class="badge bg-primary" v-else-if="isPaid(schedule.eventMembers) == 1">未入金あり</span>
                      <span v-else class="badge bg-primary">入金確認済み</span>
                    </template>
                  </template>
                  <span class="badge bg-primary" v-if="isWaiting(schedule.eventMembers) == 0">申込済</span>
                  <span class="badge bg-primary" v-else-if="isWaiting(schedule.eventMembers) == 1">キャンセル待ちあり</span>
                  <span v-else class="badge bg-danger">キャンセル待ち</span>
                  <span class="badge bg-primary" v-if="schedule.event.priceDetail > 0">有料</span><span
                    class="badge bg-danger" v-else>無料</span>
                </div>


                <div class="workTitle">
                  <router-link :to="{ name: 'EventDetail', params: { path: schedule.event.path } }">{{
                    schedule.event.title
                  }}</router-link>
                </div>
                <ul class="description">
                  <li v-if="schedule.event.targetType"><img src="@/images/icon-user-black2.svg" alt="対象">
                  <!-- {{schedule.event.targetType[0]['name'] }}{{ schedule.event.targetType.length >= 2
          ? '〜' + schedule.event.targetType[schedule.event.targetType.length - 1]['name'] : '' }} -->
                    {{ minTarget(schedule.event.targetType, schedule.event) }}
                    {{ minTarget(schedule.event.targetType, schedule.event) !== maxTarget(schedule.event.targetType, schedule.event) 
                    ? '~' + maxTarget(schedule.event.targetType, schedule.event) : '' }}
                  </li>
                  <li><img src="@/images/icon-salary-black.svg" alt="金額">{{ schedule.event.priceDetail ?
                    schedule.event.priceDetail.toLocaleString().concat('円(税込)') : '無料' }}</li>
                  <template v-if="schedule.event.destination">
                    <li class="icon-geo"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                      </svg>{{ schedule.event.destination }}</li>
                  </template>
                  <li v-if="schedule.event">
                    <img src="@/images/icon-star-black2.svg" alt="開催期間">
                    <template v-if="schedule.startAt === schedule.endAt">
                      {{ new Date(schedule.startAt).toLocaleDateString('ja-JP', { timeZone: 'Asia/Tokyo' }) }}
                    </template>
                    <template v-else>
                      {{ new Date(schedule.startAt).toLocaleDateString('ja-JP', { timeZone: 'Asia/Tokyo' }) }}{{ schedule.endAt ? '〜' + new
          Date(schedule.endAt).toLocaleDateString('ja-JP', { timeZone: 'Asia/Tokyo' }) : '' }}
                    </template>
                  </li>
                </ul>
                <div class="btn-area mb-0">
                  <router-link
                    :to="{ path: '/mypage/event_detail', query: { id: schedule.event.id, code: schedule.eventCode } }"
                    class="btn btn-apply">詳細</router-link>
                </div>
              </div>
            </div>
            <div class="latestWorks-inner result-type no-image" v-else-if="schedule.event">
              <img src="@assets/images/blank.gif" width="100%" />
              <div class="latestWorks-inner--textarea">
                <div class="companyName tag-list">
                  <template v-if="schedule.paymentMethods &&schedule.paymentMethods.length > 0 && schedule.paymentMethods[0].paymentId != 3">
                    <template v-if="!(isWaiting(schedule.eventMembers) == 2)">
                      <span class="badge bg-danger" v-if="isPaid(schedule.eventMembers) == 0">未入金</span>
                      <span class="badge bg-primary" v-else-if="isPaid(schedule.eventMembers) == 1">未入金あり</span>
                      <span v-else class="badge bg-primary">入金確認済み</span>
                    </template>
                  </template>
                  <span class="badge bg-primary" v-if="isWaiting(schedule.eventMembers) == 0">申込済</span>
                  <span class="badge bg-primary" v-else-if="isWaiting(schedule.eventMembers) == 1">キャンセル待ちあり</span>
                  <span v-else class="badge bg-danger">キャンセル待ち</span>
                  <span class="badge bg-primary" v-if="schedule.event.priceDetail > 0">有料</span><span
                    class="badge bg-danger" v-else>無料</span>
                </div>
                <div class="workTitle">
                  <router-link :to="{ name: 'EventDetail', params: { path: schedule.event.path } }">{{
          schedule.event.title
        }}</router-link>
                </div>
                <ul class="description">
                  <li v-if="schedule.event.targetType"><img src="@/images/icon-user-black2.svg" alt="対象">
                  <!-- {{schedule.event.targetType[0]['name'] }}{{ schedule.event.targetType.length >= 2
          ? '〜' + schedule.event.targetType[schedule.event.targetType.length - 1]['name'] : '' }} -->
                    {{ minTarget(schedule.event.targetType, schedule.event) }}
                    {{ minTarget(schedule.event.targetType, schedule.event) !== maxTarget(schedule.event.targetType, schedule.event) 
                    ? '~' + maxTarget(schedule.event.targetType, schedule.event) : '' }}
                  </li>
                  <li><img src="@/images/icon-salary-black.svg" alt="金額">{{ schedule.event.priceDetail ?
                    schedule.event.priceDetail.toLocaleString().concat('円(税込)') : '無料' }}</li>
                  <template v-if="schedule.event.destination">
                    <li class="icon-geo"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                      </svg>{{ schedule.event.destination }}</li>
                  </template>
                  <li v-if="schedule"><img src="@/images/icon-star-black2.svg" alt="開催期間">
                    <template v-if="schedule.startAt === schedule.endAt">
                      {{ new Date(schedule.startAt).toLocaleDateString('ja-JP', { timeZone: 'Asia/Tokyo' }) }}
                    </template>
                    <template v-else>
                      {{ new Date(schedule.startAt).toLocaleDateString('ja-JP', { timeZone: 'Asia/Tokyo' }) }}{{ schedule.endAt ? '〜' + new
          Date(schedule.endAt).toLocaleDateString('ja-JP', { timeZone: 'Asia/Tokyo' }) : '' }}
                    </template>
                  </li>
                </ul>
                <div class="btn-area mb-0">
                  <router-link
                    :to="{ path: '/mypage/event_detail', query: { id: schedule.event.id, code: schedule.eventCode } }"
                    class="btn btn-apply">詳細</router-link>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SideBar from '@/components/Layouts/SideBar.vue'
import { API } from '../../api'
// import { setActivePinia } from 'pinia'
import { handleError, onMounted, ref } from 'vue'
import { stringToDateSlash } from '@/js/util'
import { useMemberStore } from '@/stores/member';
import { handleErrors } from '@/config/handleErrors';
import { useLoadingStore } from '@/stores/loading'
import { toDateSlash } from '@/js/util'
import { TEvent, TEventSchedule, TEventTarget } from '@/types/event'
import { TEventMember, TTargetType } from '@/types/search'


const allSchedules = ref<any>([])
const beforeEvents = ref<TEventSchedule[]>([])
const afterEvents = ref<TEventSchedule[]>([])
const notices = ref<any>([])
const memberStore = useMemberStore()

const now = ref(new Date())

onMounted(async () => {
  try {
    await API.post('/api/mypage/index', { id: memberStore.id }).then((r) => {
      notices.value = r.data.notices
      allSchedules.value = r.data.allSchedules
      beforeEvents.value = (r.data.beforeEvents as any[]).map(data => new TEventSchedule(data))
      afterEvents.value = (r.data.afterEvents as any[]).map(data => new TEventSchedule(data))
      now.value = r.data.now
      useLoadingStore().stop()
    }).catch((error) => {
      useLoadingStore().stop()
      handleErrors(error)
    })
  } catch (e: any) {
    handleErrors(e)
  }
})

function isWaiting(eventMember: TEventMember[] | null): number {
  let count = 0
  if (eventMember) {
    eventMember.map(data => {
      if (data.isWaiting) {
        count++
      }
    })
    if (count == eventMember.length) {
      return 2
    } else if (count >= 1) {
      return 1
    }
  }
  return 0
}

function isPaid(eventMember: TEventMember[] | null): number {
  let waitingCount = 0;
  let fixedCount = 0;
  if (eventMember) {
    eventMember.map(data => {
      if (data.isWaiting) {
        waitingCount++
      }
      if (data.fixedAt) {
        fixedCount++
      }
    })
    if (fixedCount == (eventMember.length - waitingCount)) {
      return 2
    } else if (fixedCount >= 1) {
      return 1
    }
  }
  return 0
}

function isAfter(endAt: any): boolean {
  let isAfter = true
  const after = new Date(endAt)
  // after.setDate(date.getDate() + 7)
  if (after < now.value) {
    isAfter = false
  }
  return isAfter
}

const isNew = (day: string): boolean => {
  const startDay = new Date(day)
  const today = new Date()
  const beforeWeek = new Date(today.setDate(today.getDate() - 7))
  return startDay >= beforeWeek
}

const minTarget = (eventTargets: TTargetType[], event: TEvent) => {
  if (eventTargets && eventTargets.length > 0) {
    const start = eventTargets.reduce((min: TTargetType, current: TTargetType) => min.id < current.id ? min : current).id
    if ((event.startAge || event.startMonthAge) && (event.endAge || event.endMonthAge)) {
      const startAge = event.startAge ?? 0
      const startMonthAge = event.startMonthAge ?? 0
      const endAge = event.endAge ?? 0
      const endMonthAge = event.endMonthAge ?? 0
      const startAgeWithMonth = formatToYYMM(startAge, startMonthAge)
      const endAgeWithMonth = formatToYYMM(endAge, endMonthAge)

      if (startAgeWithMonth <= endAgeWithMonth) {
        if (startAge < start - 1) {
          return `${startAge}歳${startMonthAge > 0 ? startMonthAge + 'ヶ月' : ''}`
        } 
      }
    }
    return eventTargets.reduce((min: TTargetType, current: TTargetType) => min.id < current.id ? min : current).name
  } else {
    return ''
  }
}
const maxTarget = (eventTargets: TTargetType[], event:TEvent) => {
  if (eventTargets && eventTargets.length > 0) {
    const end = eventTargets.reduce((max, current) => max.id > current.id ? max : current).id
    if ((event.startAge || event.startMonthAge) && (event.endAge || event.endMonthAge)) {
      const startAge = event.startAge ?? 0
      const startMonthAge = event.startMonthAge ?? 0
      const endAge = event.endAge ?? 0
      const endMonthAge = event.endMonthAge ?? 0
      const startAgeWithMonth = formatToYYMM(startAge, startMonthAge)
      const endAgeWithMonth = formatToYYMM(endAge, endMonthAge)

      if (startAgeWithMonth <= endAgeWithMonth) {
        if (endAge > end - 1) {
          return `${endAge}歳${endMonthAge > 0 ? endMonthAge + 'ヶ月' : ''}`
        } 
      }
    }
    return eventTargets.reduce((max, current) => max.id > current.id ? max : current).name
  } else {
    return ''
  }
}
function formatToYYMM(years: number, months: number): string {
  const formattedYears = years.toString().padStart(2, '0')
  const formattedMonths = months.toString().padStart(2, '0')
  return `${formattedYears}${formattedMonths}`
}

</script>
