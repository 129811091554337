<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/Top">HOME</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">プログラム検索</li>
    </ol>
  </nav>
  <div class="container-fluid">
    <div class="row mypage-contents">
      <side-bar />
      <div class="mypage-contents_contents ml-sm-auto px-md-4 py-4" v-if="paginate">
        <h2>お気に入りプログラム</h2>
        <div class="top--section latestWorks mb-3">
          <template v-for="event in favoriteEvents" :key="event.id">
            <div class="latestWorks-inner result-type" v-if="event.images && event.images.length > 0">
              <img v-if="event.images && event.images.length > 0" :src="event.images[0].url" width="100%">
              <div class="latestWorks-inner--textarea">
                <ul class="tag-list">
                  <li class="tag-list-item tag-full">{{ event.categoryType?.name }}</li>
                  <li class="tag-list-item tag-full">{{ event.kindType?.name }}</li>
                  <li class="tag-list-item" v-for="membership in displayMembership(event)" :key="membership.id">{{
        membership.name }}</li>
                  <li class="tag-list-item tag-paid">{{ event.priceDetail ? '有料' : '無料' }}</li>
                  <li class="badge bg-primary">{{ receptionStatus(event.participants, event) }}</li>
                </ul>
                <div class="workTitle">
                  <router-link :to="{ name: 'EventDetail', params: { path: event.path } }">{{ event.title
                    }}</router-link>
                </div>
                <ul class="description">
                  <li>
                    <p v-html="event.content.replace(/\n/g, '<br>')"></p>
                  </li>
                  <li><img src="@images/icon-user-black2.svg" alt="対象">
                    <span v-if="Array.isArray(event.eventTargets) && event.eventTargets.length > 0">
                        {{ targetRage(event.eventTargets, event) }}
                    </span>
                  </li>
                  <li><img src="@images/icon-salary-black.svg" alt="給料">{{ event.priceDetail ?
        event.priceDetail.toLocaleString().concat('円(税込)') : '無料' }}</li>
                  <template v-for="schedule in event.eventSchedules" :key="schedule.id">
                    <li><img src="@images/icon-star-black2.svg" alt="開催期間">
                      <template v-if="schedule.startAt === schedule.endAt && event">
                        {{ toDateSlash(schedule.startAt) }}{{ displayReceptionStatus(event?.participants.find(p =>
        p.eventCode === parseInt(schedule.eventCode))) }}
                      </template>
                      <template v-else>
                        {{ toDateSlash(schedule.startAt) }}{{ '〜' }}{{ toDateSlash(schedule.endAt) }}{{
        displayReceptionStatus(event?.participants.find(p => p.eventCode ===
          parseInt(schedule.eventCode))) }}
                      </template>
                    </li>
                  </template>
                </ul>
              </div>
              <div class="btn-area mb-0" v-if="memberStore.isLoggedIn">
                <!-- <button class="btn btn-apply favo favo-active" v-if="memberStore.isLoggedIn && isFavorite(event)"> -->
                <template v-if="isApply(event) && isParticipatable(event.eventTargets, event, parent, children)">
                  <button class="btn btn-apply favo favo-active" @click="handleFavorite(event, false)"
                    v-if="isFavorite(event)">
                    <span class="favo-icon"><i class="bi bi-star-fill"></i></span><span>お気に入り済み</span>
                  </button>
                  <button class="btn btn-apply favo" @click="handleFavorite(event, true)" v-else>
                    <span class="favo-icon"><i class="bi bi-star-fill"></i></span><span>お気に入り登録</span>
                  </button>
                </template>
                <template v-if="isAttend(event) && isApply(event) && isParticipatable(event.eventTargets, event, parent, children)">
                  <button disabled class="btn btn-apply entry-program" v-if="isApplied(event)"><span>応募済み</span><i
                      class="bi bi-chevron-right"></i></button>
                  <button disabled class="btn btn-apply entry-program"
                    v-else-if="receptionStatus(event.participants) === '満席'"><span>満席</span><i
                      class="bi bi-chevron-right"></i></button>
                      <router-link class="btn btn-apply entry-program"
                        :to="{ name: 'EventDetail', params: { path: event.path } }" target="_blank"
                        v-else-if="receptionStatus(event.participants) === 'キャンセル待ち'"><span>キャンセル待ちする</span><i
                          class="bi bi-chevron-right"></i></router-link>
                  <router-link class="btn btn-apply entry-program" v-else
                    :to="{ name: 'EventDetail', params: { path: event.path } }" target="_blank"><span>予約する</span><i
                      class="bi bi-chevron-right"></i></router-link>
                </template>
                <template v-if="event.recruitmentPeriodEnd && new Date(event.recruitmentPeriodEnd) < new Date()">
                  <button disabled class="btn btn-apply entry-program btn-danger">
                    <span>受付終了</span><i class="bi bi-chevron-right"></i>
                  </button>
                </template>
              </div>
            </div>
            <div class="latestWorks-inner result-type no-image" v-else>
              <div class="latestWorks-inner--textarea">
                <ul class="tag-list">
                  <li class="tag-list-item tag-full">{{ event.categoryType?.name }}</li>
                  <li class="tag-list-item tag-full">{{ event.kindType?.name }}</li>
                  <li class="tag-list-item" v-for="membership in displayMembership(event)" :key="membership.id">{{
        membership.name }}</li>
                  <li class="tag-list-item tag-paid">{{ event.priceDetail ? '有料' : '無料' }}</li>
                  <li class="badge bg-primary">{{ receptionStatus(event.participants, event) }}</li>

                </ul>
                <div class="workTitle">
                  <router-link :to="{ name: 'EventDetail', params: { path: event.path } }">{{ event.title
                    }}</router-link>
                </div>
                <ul class="description">
                  <li>
                    <p v-html="event.content.replace(/\n/g, '<br>')"></p>
                  </li>
                  <li><img src="@images/icon-user-black2.svg" alt="対象">
                    <span v-if="Array.isArray(event.eventTargets) && event.eventTargets.length > 0">
                        {{ targetRage(event.eventTargets, event) }}
                    </span>
                  </li>
                  <li><img src="@images/icon-salary-black.svg" alt="給料">{{ event.priceDetail ?
        event.priceDetail.toLocaleString().concat('円(税込)') : '無料' }}</li>
                  <template v-for="schedule in event.eventSchedules" :key="schedule.id">
                    <li><img src="@images/icon-star-black2.svg" alt="開催期間">
                      <template v-if="schedule.startAt === schedule.endAt && event">
                        {{ toDateSlash(schedule.startAt) }}{{ displayReceptionStatus(event?.participants.find(p =>
        p.eventCode === parseInt(schedule.eventCode))) }}
                      </template>
                      <template v-else>
                        {{ toDateSlash(schedule.startAt) }}{{ '〜' }}{{ toDateSlash(schedule.endAt) }}{{
        displayReceptionStatus(event?.participants.find(p => p.eventCode ===
          parseInt(schedule.eventCode))) }}
                      </template>
                    </li>
                  </template>
                </ul>
              </div>
              <div class="btn-area mb-0" v-if="memberStore.isLoggedIn">
                <!-- <button class="btn btn-apply favo favo-active" v-if="memberStore.isLoggedIn && isFavorite(event)"> -->
                <template v-if="isApply(event) && isParticipatable(event.eventTargets, event, parent, children)">
                  <button class="btn btn-apply favo favo-active" @click="handleFavorite(event, false)"
                    v-if="isFavorite(event)">
                    <span class="favo-icon"><i class="bi bi-star-fill"></i></span><span>お気に入り済み</span>
                  </button>
                  <button class="btn btn-apply favo" @click="handleFavorite(event, true)" v-else>
                    <span class="favo-icon"><i class="bi bi-star-fill"></i></span><span>お気に入り登録</span>
                  </button>
                </template>
                <template v-if="isAttend(event) && isApply(event) && isParticipatable(event.eventTargets, event, parent, children)">
                  <button disabled class="btn btn-apply entry-program" v-if="isApplied(event)"><span>応募済み</span><i
                      class="bi bi-chevron-right"></i></button>
                  <button disabled class="btn btn-apply entry-program"
                    v-else-if="receptionStatus(event.participants) === '満席'"><span>満席</span><i
                      class="bi bi-chevron-right"></i></button>
                  <router-link :to="{ path: '/event/entry', query: { id: event?.id } }"
                    class="btn btn-apply entry-program"
                    v-else-if="event && receptionStatus(event.participants) === 'キャンセル待ち'"><span>キャンセル待ちする</span><i
                      class="bi bi-chevron-right"></i></router-link>
                  <router-link class="btn btn-apply entry-program" v-else
                    :to="{ name: 'EventDetail', params: { path: event.path } }" target="_blank"><span>予約する</span><i
                      class="bi bi-chevron-right"></i></router-link>
                </template>
                <template v-if="event.recruitmentPeriodEnd && new Date(event.recruitmentPeriodEnd) < new Date()">
                  <button disabled class="btn btn-apply entry-program btn-danger">
                    <span>受付終了</span><i class="bi bi-chevron-right"></i>
                  </button>
                </template>
              </div>
            </div>
          </template>
        </div>
        <pagination class="pagination justify-content-center" v-model="paginate.currentPage" :page-range="3"
          :margin-pages="2" :page-count="paginate.lastPage" :records="paginate.total" :per-page="paginate.perPage"
          :click-handler="handlePage" :prev-text="prev" :next-text="next" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SideBar from '@/components/Layouts/SideBar.vue'
import { computed, markRaw, onMounted, ref } from 'vue'
import { LocationQuery, RouteParamsRaw, onBeforeRouteUpdate, useLink, useRoute, useRouter } from 'vue-router'
import { useMemberStore } from '@/stores/member'
import { useEventStore } from '@/stores/event'
import { TMember } from '@/types/member'
import { toDateSlash, displayReceptionStatus, receptionStatus, getGrade, getAgeWithMonths, toDateHyphenJst } from '@/js/util'
import { useField, useForm, configure, Field } from 'vee-validate'
import * as yup from 'yup'
import { TBase, TCategoryType, TEvent, TKindType, TPaginate, TShopType, TTargetType } from '@/types/search'
import Pagination from 'vuejs-paginate-next'
import { TEventParticipant, TEventTarget } from '@/types/event'
import { useLoadingStore } from '@/stores/loading'
import { queryToNumber, queryToNumbersArray, queryToString } from '../../js/route-query-helper'
import { handleErrors } from '@/config/handleErrors'



const route = useRoute()
const router = useRouter()
const memberStore = useMemberStore()
const memberIds = ref<number[]>([])
const parentMembershipsIds = ref<number[]>([])
const childMembershipsIds = ref<number[]>([])
const parent = ref<TMember>()
const children = ref<TMember[]>()

const favoriteEvents = ref<TEvent[]>([])
const paginate = ref<TPaginate>()
const prev = `<span aria-hidden="true"><i class="bi bi-chevron-double-left"></i></span>
              <span class="sr-only">Previous</span>`
const next = `<span aria-hidden="true"><i class="bi bi-chevron-double-right"></i></span>
              <span class="sr-only">Next</span>`

const memberId = ref(0)
const paramPage = ref<number | undefined>(0)

useLoadingStore().start()
onMounted(async () => {
  try {
    const {
      paginate: fetchPaginate,
      favoriteEvents: fetchFavoriteEvents,
      memberIds: fetchMemberIds,
      parentMembershipsIds: fetchParentMembershipsIds,
      childMembershipsIds: fetchChildMembershipsIds,
      parent: fetchParent,
      children: fetchChildren,
    }
      = await TEvent.fetchFavorite(
        {
          page: 1
        }
      )
    paginate.value = fetchPaginate
    favoriteEvents.value = fetchFavoriteEvents
    memberIds.value = fetchMemberIds
    parentMembershipsIds.value = fetchParentMembershipsIds
    childMembershipsIds.value = fetchChildMembershipsIds
    parent.value = fetchParent
    children.value = fetchChildren
  } catch (e: any) {
    handleErrors(e)
  } finally {
    useLoadingStore().stop()
  }
})

const targetRage = (eventTargets: TEventTarget[], event: TEvent): string => {
  eventTargets.sort((a, b) => a.id - b.id)
  const startTarget = eventTargets[0].id
  const endTarget = eventTargets[eventTargets.length - 1].id
  const start = eventTargets[0].name
  const end = eventTargets[eventTargets.length - 1].name

  if ((event.startAge || event.startMonthAge) && (event.endAge || event.endMonthAge)) {
    const startAge = event.startAge ?? 0
    const startMonthAge = event.startMonthAge ?? 0
    const endAge = event.endAge ?? 0
    const endMonthAge = event.endMonthAge ?? 0
    const startAgeWithMonth = formatToYYMM(startAge, startMonthAge)
    const endAgeWithMonth = formatToYYMM(endAge, endMonthAge)

    if (startAgeWithMonth <= endAgeWithMonth) {
      if (startAge < Number(startTarget) - 1 && endAge > Number(endTarget) - 1) {
        return `${startAge}歳${startMonthAge > 0 ? startMonthAge + 'ヶ月' : ''}〜${endAge}歳${endMonthAge > 0 ? endMonthAge + 'ヶ月' : ''}`
      } 
      else if (startAge < Number(startTarget) - 1) {
        return `${startAge}歳${startMonthAge > 0 ? startMonthAge + 'ヶ月' : ''}〜${end}`
      } 
      else if ( endAge > Number(endTarget) - 1) {
        return `${start}〜${endAge}歳${endMonthAge > 0 ? endMonthAge + 'ヶ月' : ''}`
      }
    }
  }
  return start.concat('〜').concat(end)
}

function formatToYYMM(years: number, months: number): string {
  const formattedYears = years.toString().padStart(2, '0')
  const formattedMonths = months.toString().padStart(2, '0')
  return `${formattedYears}${formattedMonths}`
}

const get2digFiscalYear = (dateString) => {
  const now = new Date(dateString)
  const year = now.getFullYear()
  const month = now.getMonth() + 1
  const fiscalYear = month < 4 ? year - 1 : year
  return fiscalYear % 100
}

function isParticipatable(eventTargets: TEventTarget[], event: TEvent, parent: TMember | undefined, children: TMember[] | undefined): boolean {
  const memberships = event.memberships.filter(m => {
    return m.pivot.isApplicable
  })
  let allMembers: TMember[] = [];
  if ((parent && event.applicantParent) && (memberships.some(m => parentMembershipsIds.value.includes(m.id)))) {
    allMembers.push(parent);
  }
  if (((children && children.length > 0) && event.applicantChild) && (memberships.some(m => childMembershipsIds.value.includes(m.id)))) {
    allMembers.push(...children);
  }
  for (const member of allMembers) {
    if (!member) continue;
    let grade = getGrade(member.birthday)
    // 次年度のプログラムだったら学年をあげて考える
    if (event && event?.serverNow) {
      if (event.fiscalYear > get2digFiscalYear(event?.serverNow)) {
        grade = grade + (event.fiscalYear - get2digFiscalYear(event?.serverNow) )
        if (grade >= 21) {
          grade = 20
        } 
      }
    }

    let isParticipatableGrade: number[] = []
    for (const target of eventTargets ? eventTargets : []) {
      switch (target.name) {
        case '0歳': isParticipatableGrade.push(1)
          break
        case '1歳': isParticipatableGrade.push(2)
          break
        case '2歳': isParticipatableGrade.push(3)
          break
        case '年少': isParticipatableGrade.push(4)
          break
        case '年中': isParticipatableGrade.push(5)
          break
        case '年長': isParticipatableGrade.push(6)
          break
        case '小学1年生': isParticipatableGrade.push(7)
          break
        case '小学2年生': isParticipatableGrade.push(8)
          break
        case '小学3年生': isParticipatableGrade.push(9)
          break
        case '小学4年生': isParticipatableGrade.push(10)
          break
        case '小学5年生': isParticipatableGrade.push(11)
          break
        case '小学6年生': isParticipatableGrade.push(12)
          break
        case '中学1年生': isParticipatableGrade.push(13)
          break
        case '中学2年生': isParticipatableGrade.push(14)
          break
        case '中学3年生': isParticipatableGrade.push(15)
          break
        case '高校1年生': isParticipatableGrade.push(16)
          break
        case '高校2年生': isParticipatableGrade.push(17)
          break
        case '高校3年生': isParticipatableGrade.push(18)
          break
        case '大学生・社会人': isParticipatableGrade.push(19)
          break
        case '成人': isParticipatableGrade.push(20)
          break
      }
    }

    // 開始年齢/月齢、終了年齢/月齢が指定されている場合
    if (event?.startAge || event?.startMonthAge || event?.endAge || event?.endMonthAge) {
      const startAt = event.eventSchedules[0].startAt
      const startAtString = toDateHyphenJst(startAt)
      const startAtDate = new Date(`${startAtString}T00:00:00`)
      const endAt = event.eventSchedules[0].endAt
      const endAtString = toDateHyphenJst(endAt)
      const endAtDate = new Date(`${endAtString}T00:00:00`)
      const now = new Date()
      let date 
      if ((startAtDate < now) && (now < endAtDate)) {
        date = now
      } else if (startAtDate > now) {
        date = startAtDate
      }
      const age = getAgeWithMonths(date, member.birthday)
      const ageWithMonth = formatToYYMM(age.years, age.months)

      const startAge = event.startAge ?? 0
      const startMonthAge = event.startMonthAge ?? 0
      const endAge = event.endAge ?? 0
      const endMonthAge = event.endMonthAge ?? 0
      const startAgeWithMonth = formatToYYMM(startAge, startMonthAge)
      const endAgeWithMonth = formatToYYMM(endAge, endMonthAge)

      if (isParticipatableGrade.includes(grade) || (ageWithMonth >= startAgeWithMonth && ageWithMonth <= endAgeWithMonth)) {
        return true
      }
    } else {
      if (isParticipatableGrade.includes(grade)) {
        return true
      } 
    }
  }
  return false; 
}

const isFavorite = computed(() => {
  return (event) => {
    const fav = event.favorites.find(f =>
      f.memberId === useMemberStore().$state.id && event.id === f.eventId
    )
    return fav ? true : false;
  }
})

const displayMembership = (event: TEvent) => {
  const memberships = event.memberships.filter(m => {
    return m.pivot.isApplicable
  })
  return memberships
}

const handleFavorite = async (event: TEvent, isFavorite: boolean) => {
  try {
    useLoadingStore().start()
    const response = await TEvent.favorite({ eventId: event.id, isFavorite, page: paginate?.value?.currentPage })
    if (response.data.status === 'failed') {
      alert('お気に入りの更新に失敗しました')
    }
    const {
      favoriteEvents: fetchEvent,
      paginate: fetchPaginate,
      memberIds: fetchMemberIds,
      parentMembershipsIds: fetchParentMembershipsIds,
      childMembershipsIds: fetchChildMembershipsIds,
      parent: fetchParent,
      children: fetchChildren,
    }
      = await TEvent.fetchFavorite({
        page: paginate?.value?.currentPage,
      })
    favoriteEvents.value = fetchEvent
    paginate.value = fetchPaginate
    memberIds.value = fetchMemberIds
    parentMembershipsIds.value = fetchParentMembershipsIds
    childMembershipsIds.value = fetchChildMembershipsIds
    parent.value = fetchParent
    children.value = fetchChildren
    // await handleSearch(paginate?.value?.currentPage)
    // else {
    //   events.value = (response.data.events as any[]).map(data => new TEvent(data))
    //   paginate.value = new TPaginate(response.data.meta)
    // }
  } catch (e: any) {
    handleErrors(e)
  } finally {
    useLoadingStore().stop()
  }
}

// const isApplied = computed(() => (event: TEvent): boolean => {
//   let applyCount = event.eventMember.length
//   let family: any[] = []
//   if (event.applicantParent) family.push(...memberIds.value.filter(id => id === useMemberStore().id)) // 親を削除
//   if (event.applicantChild) family.push(...memberIds.value.filter(id => id !== useMemberStore().id)) // 親以外を残す
//   return applyCount >= family.length * event.eventSchedules.length
// })

const isApplied = computed(() => (event: TEvent): boolean => {
  if (event?.eventMember === null || event?.eventMember === undefined) return true
  let applyCount = event.eventMember.length
  let family: number[] = []
  if (!event.applicantParent && event.applicantChild && memberIds.value.length === 1) {
    return false
  } else {
    if (event.applicantParent) family.push(...memberIds.value.filter(id => Number(id) === Number(useMemberStore().id)) ) // 親を削除
    if (event.applicantChild) family.push(...memberIds.value.filter(id => Number(id) !== Number(useMemberStore().id))) // 親以外を残す
    return applyCount >= family.length *  event.eventSchedules.length
  }
})

const isAttend = computed(() => (event: TEvent): boolean => {
  return event.isApply;
}
)

const isApply = (event: TEvent): boolean => {
  const memberships = event.memberships.filter(m => {
    return m.pivot.isApplicable
  })
  let memberMembershipsIds: number[] = [];

  if (event.applicantParent && event.applicantChild) {
    memberMembershipsIds = [...new Set([...parentMembershipsIds.value, ...childMembershipsIds.value])];
  } else if (event.applicantParent) {
    memberMembershipsIds = [...parentMembershipsIds.value];
  } else if (event.applicantChild) {
    memberMembershipsIds = [...childMembershipsIds.value];
  }
  
  if (memberships && memberships.length > 0) {
    return memberships.some(m => memberMembershipsIds.includes(m.id))
  } else {
    return false
  }
}

const handlePage = async (page?: number) => {
  try {
    useLoadingStore().start()
    paramPage.value = page
    const {
      favoriteEvents: fetchEvent,
      paginate: fetchPaginate,
    }
      = await TEvent.fetchFavorite({
        page: page,
      })
    favoriteEvents.value = fetchEvent
    paginate.value = fetchPaginate
  } catch (e: any) {
    handleErrors(e)
  } finally {
    useLoadingStore().stop()
  }
}

</script>
