<template>
  <nav aria-label="breadcrumb" class="breadcrumb-area">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link to="/">HOME</router-link></li>
      <li class="breadcrumb-item"><router-link to="/mypage/index">マイページ</router-link></li>
      <li class="breadcrumb-item active" aria-current="page">過去参加プログラム確認</li>
    </ol>
  </nav>
  <div class="container-fluid">
    <div class="row mypage-contents">
      <side-bar />
      <div class="mypage-contents_contents ml-sm-auto px-md-4 py-4">
        <h2>過去参加プログラム</h2>
        <div class="top--section latestWorks mb-3">
          <template v-for="schedule in allSchedules" :key="schedule.event?.id">
            <template v-if="schedule.event">
              <div class="latestWorks-inner result-type" v-if="schedule.event.images && schedule.event.images.length > 0">
                <img v-if="schedule.event.images && schedule.event.images.length > 0" :src="schedule.event.images[0].url" width="100%">
                <div class="latestWorks-inner--textarea">
                  <div class="companyName">
                    <!-- <span class="badge bg-primary" v-if="isWaiting(schedule.event_member) == 0">申込済</span> -->
                    <!-- <span class="badge bg-primary" v-else-if="isWaiting(schedule.event_member) == 1">キャンセル待ちあり</span> -->
                    <!-- <span v-else class="badge bg-danger">キャンセル待ち</span> -->
                    <span class="badge bg-primary" v-if="schedule.event.price_detail > 0">有料</span><span
                      class="badge bg-danger" v-else>無料</span>
                  </div>
                  <div class="workTitle">
                    <router-link :to="{ name: 'EventDetail', params: { path: schedule.event.path } }"
                      v-if="schedule.event">{{ schedule.event.title }}</router-link>
                  </div>
                  <ul class="description">
                    <li v-if="schedule.event.target_types"><img src="@/images/icon-user-black2.svg" alt="対象">
                    <!-- {{schedule.event.target_types[0]['name'] }}{{ schedule.event.target_types.length >= 2
                      ? '〜' + schedule.event.target_types[schedule.event.target_types.length - 1]['name'] : '' }} -->
                      {{ minTarget(schedule.event.target_types, schedule.event) }}
                      {{ minTarget(schedule.event.target_types, schedule.event) !== maxTarget(schedule.event.target_types, schedule.event) 
                      ? '~' + maxTarget(schedule.event.target_types, schedule.event) : '' }}
                    </li>
                    <li><img src="@/images/icon-salary-black.svg" alt="金額">{{ schedule.event.price_detail ?
                      schedule.event.price_detail.toLocaleString().concat('円(税込)') : '無料' }}</li>
                    <template v-if="schedule.event.destination">
                      <li class="icon-geo"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                        </svg>{{ schedule.event.destination }}</li>
                    </template>
                    <li v-if="schedule"><img src="@/images/icon-star-black2.svg" alt="開催期間">
                      <template v-if="schedule.start_at === schedule.end_at">
                        {{  toDateSlash(new Date(schedule.start_at)) }}
                      </template>  
                      <template v-else>
                        {{
                          toDateSlash(new Date(schedule.start_at)) }}{{ schedule.end_at ? '〜' + toDateSlash(new Date(schedule.end_at))
                          : ''
                          }}
                      </template>
                    </li>
                    <li>
                      <p v-html="schedule.event.content.replace(/\n/g, '<br>')">
                      </p>
                    </li>
                  </ul>
                  <div class="btn-area mb-0">
                    <router-link v-if="schedule"
                      :to="{ path: '/mypage/event_detail', query: { id: schedule.event.id, code: schedule.event_code } }"
                      class="btn btn-apply">詳細を確認</router-link>
                  </div>
                </div>
              </div>
              <div class="latestWorks-inner result-type no-image" v-else>
                <div class="latestWorks-inner--textarea">
                  <div class="companyName">
                    <!-- <span class="badge bg-primary" v-if="isWaiting(schedule.event_member) == 0">申込済</span> -->
                    <!-- <span class="badge bg-primary" v-else-if="isWaiting(schedule.event_member) == 1">キャンセル待ちあり</span> -->
                    <!-- <span v-else class="badge bg-danger">キャンセル待ち</span> -->
                    <span class="badge bg-primary" v-if="schedule.event.price_detail > 0">有料</span><span
                      class="badge bg-danger" v-else>無料</span>
                  </div>
                  <div class="workTitle">
                    <router-link :to="{ name: 'EventDetail', params: { path: schedule.event.path } }"
                      v-if="schedule.event">{{ schedule.event.title }}</router-link>
                  </div>
                  <ul class="description">
                    <li v-if="schedule.event.target_types"><img src="@/images/icon-user-black2.svg" alt="対象">
                    <!-- {{schedule.event.target_types[0]['name'] }}{{ schedule.event.target_types.length >= 2
                      ? '〜' + schedule.event.target_types[schedule.event.target_types.length - 1]['name'] : '' }} -->
                      {{ minTarget(schedule.event.target_types, schedule.event) }}
                      {{ minTarget(schedule.event.target_types, schedule.event) !== maxTarget(schedule.event.target_types, schedule.event) 
                      ? '~' + maxTarget(schedule.event.target_types, schedule.event) : '' }}
                    </li>
                    <li><img src="@/images/icon-salary-black.svg" alt="金額">{{ schedule.event.price_detail ?
                      schedule.event.price_detail.toLocaleString().concat('円(税込)') : '無料' }}</li>
                    <template v-if="schedule.event.destination">
                      <li class="icon-geo"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                        </svg>{{ schedule.event.destination }}</li>
                    </template>
                    <li v-if="schedule"><img src="@/images/icon-star-black2.svg" alt="開催期間">
                      <template v-if="schedule.start_at === schedule.end_at">
                        {{ toDateSlash(new Date(schedule.start_at)) }}
                      </template>  
                      <template v-else>
                        {{
                          toDateSlash(new Date(schedule.start_at)) }}{{ schedule.end_at ? '〜' +  toDateSlash(new Date(schedule.end_at))
                          : ''
                          }}
                      </template>  
                    </li>
                    <li>
                      <p v-html="schedule.event.content.replace(/\n/g, '<br>')">
                      </p>
                    </li>
                  </ul>
                  <div class="btn-area mb-0">
                    <router-link v-if="schedule"
                      :to="{ path: '/mypage/event_detail', query: { id: schedule.event.id, code: schedule.event_code } }"
                      class="btn btn-apply">詳細を確認</router-link>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
        <pagination v-if="paginate" class="pagination justify-content-center" v-model="paginate.currentPage"
          :page-range="3" :margin-pages="2" :page-count="paginate.lastPage" :records="paginate.total"
          :per-page="paginate.perPage" :click-handler="paginata" :prev-text="prev" :next-text="next" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import SideBar from '@/components/Layouts/SideBar.vue'
import { API } from '../../api'
import { handleError, onMounted, ref } from 'vue'
import { stringToDateSlash, toDateSlash } from '@/js/util'
import { useMemberStore } from '@/stores/member';
import { handleErrors } from '@/config/handleErrors';
import { useLoadingStore } from '@/stores/loading'
import { TPaginate, TTargetType } from '@/types/search'
import Pagination from 'vuejs-paginate-next'
import { TEvent } from '@/types/event'

const allSchedules = ref<any>([])
const memberStore = useMemberStore()
const meta: any = ref({})

const paginate = ref<TPaginate>()
const prev = `<span aria-hidden="true"><i class="bi bi-chevron-double-left"></i></span>
              <span class="sr-only">Previous</span>`
const next = `<span aria-hidden="true"><i class="bi bi-chevron-double-right"></i></span>
              <span class="sr-only">Next</span>`

onMounted(async () => {
  useLoadingStore().start()
  try{
    const {
      allSchedules: fetchAllSchedules,
      paginate: fetchPaginate,
    }
      = await TPaginate.page({
        id: memberStore.id,
        page: 1,
      })
    allSchedules.value = fetchAllSchedules
    paginate.value = fetchPaginate
  } catch(e: any) {
    handleErrors(e)
  } finally {
    useLoadingStore().stop()
  
  }
})

function isWaiting(eventMember: any[]): number {
  let count = 0;
  eventMember.map(data => {
    if (data.is_waiting) {
      count++
    }
  })
  if (count == eventMember.length) {
    return 2
  } else if (count >= 1) {
    return 1
  }
  return 0
}

const paginata = async (page?: number) => {
  useLoadingStore().start()
  try {
    const {
      allSchedules: fetchAllSchedules,
      paginate: fetchPaginate,
    }
      = await TPaginate.page({
        id: memberStore.id,
        page: page,
      })
    allSchedules.value = fetchAllSchedules
    paginate.value = fetchPaginate
  } catch (e: any) {
    handleErrors(e)
  } finally {
    useLoadingStore().stop()
  }
}

const minTarget = (eventTargets: TTargetType[], event: TEvent) => {
  if (eventTargets && eventTargets.length > 0) {
    const start = eventTargets.reduce((min: TTargetType, current: TTargetType) => min.id < current.id ? min : current).id
    if ((event.start_age || event.start_month_age) && (event.end_age || event.end_month_age)) {
      const startAge = event.start_age ?? 0
      const startMonthAge = event.start_month_age ?? 0
      const endAge = event.end_age ?? 0
      const endMonthAge = event.end_month_age ?? 0
      const startAgeWithMonth = formatToYYMM(startAge, startMonthAge)
      const endAgeWithMonth = formatToYYMM(endAge, endMonthAge)

      if (startAgeWithMonth <= endAgeWithMonth) {
        if (startAge < start - 1) {
          return `${startAge}歳${startMonthAge > 0 ? startMonthAge + 'ヶ月' : ''}`
        } 
      }
    }
    return eventTargets.reduce((min: TTargetType, current: TTargetType) => min.id < current.id ? min : current).name
  } else {
    return ''
  }
}
const maxTarget = (eventTargets: TTargetType[], event:TEvent) => {
  if (eventTargets && eventTargets.length > 0) {
    const end = eventTargets.reduce((max, current) => max.id > current.id ? max : current).id
    if ((event.start_age || event.start_month_age) && (event.end_age || event.end_month_age)) {
      const startAge = event.start_age ?? 0
      const startMonthAge = event.start_month_age ?? 0
      const endAge = event.end_age ?? 0
      const endMonthAge = event.end_month_age ?? 0
      const startAgeWithMonth = formatToYYMM(startAge, startMonthAge)
      const endAgeWithMonth = formatToYYMM(endAge, endMonthAge)

      if (startAgeWithMonth <= endAgeWithMonth) {
        if (endAge > end - 1) {
          return `${endAge}歳${endMonthAge > 0 ? endMonthAge + 'ヶ月' : ''}`
        } 
      }
    }
    return eventTargets.reduce((max, current) => max.id > current.id ? max : current).name
  } else {
    return ''
  }
}
function formatToYYMM(years: number, months: number): string {
  const formattedYears = years.toString().padStart(2, '0')
  const formattedMonths = months.toString().padStart(2, '0')
  return `${formattedYears}${formattedMonths}`
}

</script>